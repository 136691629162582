import type { PaymentMethod, PaymentProvider, Till } from "@repo/types";

import { getEmployee } from "~/providers/store/employee";
import type { Printer } from "~/types/hardware";

type IsPaymentButtonHiddenArgs = {
  till: Till;
  printer: Printer | null;
  modulePaymentMethod: PaymentMethod;
  hasHybridBilling: boolean;
  totalUnit: number;
  unitSaldo: number | null;
  restPrice: string | null | undefined;
  isBasketEmpty: boolean;
  isSplitPaymentActive: boolean;
};

const paymentTypes = [
  "billing",
  "card",
  "cash",
  "mobilepay_own",
  "credit",
  "zero_payment",
];

type PaymentProviders = (typeof paymentTypes)[number];

export const isPaymentButtonHidden = ({
  till,
  printer,
  modulePaymentMethod,
  hasHybridBilling,
  totalUnit,
  unitSaldo,
  restPrice,
  isBasketEmpty,
  isSplitPaymentActive,
}: IsPaymentButtonHiddenArgs) => {
  const { groupName } = getEmployee();

  const hiddenPayments: Partial<Record<PaymentProviders, boolean>> = {
    billing: true,
    card: true,
    cash: true,
    mobilepay_own: true,
    credit: true,
    zero_payment: true,
  };

  if (!isBasketEmpty && totalUnit === 0) {
    hiddenPayments.zero_payment = false;
    return hiddenPayments;
  }

  const isReimbursement = totalUnit < 0;

  const isFullyHybridBilled =
    till.type === "self-service" &&
    hasHybridBilling &&
    totalUnit === 0 &&
    !isBasketEmpty;

  const isGuest =
    till.type === "self-service" && till.config.guestSelector === groupName;

  // When payment method is billing and the user is a guest, we want to show only the card payment button
  if (modulePaymentMethod === "billing" && isGuest) {
    hiddenPayments.card = false;
    return hiddenPayments;
  }

  // When payment method is billing, we want to hide all payment buttons except billing
  // When is self-service and has hybrid billing and the basket total is 0, we want to hide all payment buttons except billing, even if the module is credit type.
  if (
    (modulePaymentMethod === "billing" || isFullyHybridBilled) &&
    !isReimbursement
  ) {
    hiddenPayments.billing = false;
    return hiddenPayments;
  }

  const hasSaldo = Boolean(unitSaldo);
  const hasRestPrice = Boolean(restPrice);

  if (hasSaldo && !hasRestPrice) {
    hiddenPayments.credit = false;
  }

  const tillIncludesPaymentProvider = (paymentProvider: PaymentProvider) =>
    till.paymentProviders?.includes(paymentProvider);

  // When there is the terminal and the user has no saldo to cover the cost, we want to show the card payment button
  if (
    (!hasSaldo && tillIncludesPaymentProvider("card")) ||
    (hasSaldo && hasRestPrice)
  ) {
    hiddenPayments.card = false;
  }

  if (
    tillIncludesPaymentProvider("cash") &&
    printer &&
    printer.hasCashDrawer &&
    till.type === "attended" &&
    !isSplitPaymentActive &&
    (!hasSaldo || (hasSaldo && hasRestPrice))
  ) {
    hiddenPayments.cash = false;
  }

  if (
    tillIncludesPaymentProvider("mobilepay_own") &&
    !isReimbursement &&
    (!hasSaldo || (hasSaldo && hasRestPrice))
  ) {
    hiddenPayments.mobilepay_own = false;
  }

  return hiddenPayments;
};
